<template>
    <div id="commercial-tenant-selector">
        <van-pull-refresh class="shop-list-container" v-model="refreshing" @refresh="onRefresh()">
            <van-list
                v-if="!isEmpty"
                v-model="loading"
                :finished="finished"
                finished-text="没有更多商户了"
                @load="onLoad()">
                <h1 class="title">请选择要添加商品的商户</h1>
                <div v-for="(item,index) in shopList" :key="index" class="shop-item-box" @click="toAddGoods(item)">
                    <div class="basic-info-box">
                        <img class="avatar" v-lazy="item['market_logo']" alt=""/>
                        <h2 class="name">{{item['market_name']}}</h2>
                    </div>
                    <img class="arrow-front" src="../assets/images/right-arrow.png" alt/>
                </div>
            </van-list>
            <van-empty v-else
                       :image="require(`@images/no-goods.png`)"
                       description="暂无任何商户信息"/>
        </van-pull-refresh>
    </div>
</template>

<script>
    import {getShops} from "../api/requist";
    import {Toast} from "vant";
    import router from "../router";

    export default {
        name: "shopSelector",
        data() {
            return {
                refreshing: false,
                loading: false,
                finished: false,
                isEmpty: false,
                pageCurrent: 0,
                pageSize: 15,
                shopList: []
            }
        },
        methods: {
            // 点击商户跳转至添加商品页面
            toAddGoods: function (shopInfo) {
                router.push({
                    path: '/addGoods',
                    query: {shopInfo: shopInfo}
                })
            },

            // 获取商户列表
            getShops: function () {
                let _that = this;
                let _pageCurrent = this.pageCurrent;
                let formData = new FormData();
                formData.append('pageCurrent', _pageCurrent + 1 + '');
                formData.append('pageSize', this.pageSize);
                getShops(formData).then((res) => {
                    let _code = res.code;
                    if (_code === 200) {
                        let shops = res.data['MerchList'];
                        if (_pageCurrent === 0) {
                            _that.shopList = [...shops];
                        } else {
                            _that.shopList = [..._that.shopList, ...shops];
                        }
                        if (shops.length < _that.pageSize) {
                            _that.finished = true;
                        }
                        _that.refreshing = false;
                        _that.loading = false;
                        _that.pageCurrent++;
                    } else if (_code === 202) {
                        // 当暂无对应类型订单数据时，显示缺省信息
                        this.refreshing = false;
                        this.loading = false;
                        this.finished = true;
                        this.isEmpty = true;
                    } else {
                        return Toast(res.message);
                    }
                })
            },

            // 上拉加载
            onLoad: function () {
                this.getShops();
            },

            // 下拉刷新
            onRefresh: function () {
                this.finished = false;
                this.pageCurrent = 0;
                this.getShops();
            }
        }
    }
</script>

<style lang="scss">
    #commercial-tenant-selector {
        background-color: #F5F7FA;
        height: 100vh;
        overflow-y: scroll;

        .shop-list-container {
            min-height: 100vh;

            .title {
                flex: 0 66px;
                color: #303133;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                padding-top: 22px;
                padding-bottom: 22px;
                box-sizing: border-box;
            }

            .shop-item-box {
                border-radius: 4px;
                background-color: white;
                margin: 0 16px 8px 16px;
                display: flex;
                flex-direction: row;
                padding: 12px 16px;
                align-items: center;
                justify-content: space-between;

                .basic-info-box {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .avatar {
                        box-sizing: border-box;
                        border: solid 2px #F2F6FC;
                        border-radius: 50%;
                        height: 45px;
                        width: 45px;
                    }

                    .name {
                        margin-left: 8px;
                        color: #303133;
                        font-size: 18px;
                    }
                }

                .arrow-front {
                    height: 12px;
                }
            }
        }
    }
</style>
